import BasePlugin from '../BasePlugin'

export default class ExportBudgetIncomeAndExpensesFromScenario extends BasePlugin {
  async execute () {
    let scenarioId = this.context.getModel().id
    this.send({ scenarioId: scenarioId })
      .then((response) => {
        let address = `${this.context.$config.api}/etleditor/export/10`
        this.context.$http({
          method: 'post',
          url: address,
          responseType: 'arraybuffer',
          data: { 'ids': response.data }
        }).then(function (response2) {
          let blob = new Blob([response2.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          let url = window.URL.createObjectURL(blob)
          window.open(url)
        })
      })
  }
}
